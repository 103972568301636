import React from "react";
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import { HashLink } from 'react-router-hash-link';
import { HeadingFive, LiTag, MyDiv, PTag, SpanTag, UlTag } from '../common/components';
import Container from 'react-bootstrap/Container';
import Logo from "../assets/images/footerlogo.svg";
import whatsupIcon from "../assets/images/whatsapp_icon.svg";
import linkedIcon from "../assets/images/linkedin_icon.svg";
import { Link } from "react-router-dom";

function Footer({ setExpanded }){
      const [showButton, setShowButton] = useState(false);
 
    useEffect(() => {
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > 300) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      });
    }, []);
  
    const handleLinkClick = () => {
      setExpanded(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const currentYear = new Date().getFullYear();

    const handleFooterLinkClick = () => {
      setExpanded(false);
    };
  

    return(
        <>
        <MyDiv className="main-footer">        
        <MyDiv className="inner-page-footer links">
            <Container className="footer-container">
                <Row>
                    <Col md={3}  className="footer footer-logo">
                    <MyDiv className="footer-bottom-info">
                        <Link className="mail-us social" to="/" as={Link} onClick={handleLinkClick}><img src={Logo}  /></Link>	
                    </MyDiv>
                    </Col>
                    <Col  md={3} xs={12}  className="footer">
                    <MyDiv className="footer-bottom-info">
                            <UlTag className="footer-nav ms-auto">
                            <LiTag className="footer-item mx-0"><Nav.Link href="/" className="footer-link" onClick={handleFooterLinkClick}>Home</Nav.Link></LiTag>
                            <LiTag className="footer-item mx-0"><HashLink smooth to="/#about-us-section" className="footer-link" onClick={handleFooterLinkClick}>About Us</HashLink></LiTag>
                            <LiTag className="footer-item mx-0"><HashLink className="footer-link" smooth  to='/#beebox-section'  onClick={handleFooterLinkClick}> The Beebox </HashLink></LiTag>
                            <LiTag className="footer-item mx-0"><HashLink className="footer-link" smooth  to='/#products-services'  onClick={handleFooterLinkClick}> Products & Services </HashLink></LiTag>
                         </UlTag>
                    </MyDiv>
                    </Col>
                    <Col  md={3}  xs={12}className="footer">
                    <MyDiv className="footer-bottom-info">
                        <UlTag className="footer-nav ms-auto">
                            <LiTag className="footer-item mx-0"><HashLink smooth to="/#sustainability-section" className="footer-link"  onClick={handleFooterLinkClick}>Sustainability</HashLink></LiTag>
                            <LiTag className="footer-item mx-0"><Nav.Link className="footer-link" to="./imprint" as={Link}  onClick={handleLinkClick}>Imprint</Nav.Link></LiTag>
                            <LiTag className="footer-item mx-0"><Nav.Link className="footer-link" to="./privacy-policy" as={Link}  onClick={handleLinkClick}>Privacy Policy</Nav.Link></LiTag>
                            <LiTag className="footer-item mx-0"><HashLink smooth to="/#contact-section" className="footer-link"  onClick={handleFooterLinkClick}>Contact</HashLink></LiTag>
                        </UlTag>
                    </MyDiv>
                    </Col> 
                    <Col  md={3} xs={12}className="footer">
                    <MyDiv className="footer-bottom-info">
                      <HeadingFive>Contact Us</HeadingFive>
                       <UlTag className="footer-nav ms-auto">
                            <LiTag className="footer-item mx-0">
                             <MyDiv className="footer-link"> <SpanTag>Beebox GmbH</SpanTag> <br />
                              Donaustr. 4b <br />
                              65451 Kelsterbach <br />
                              Email: <a href="mailto:hello@beebox360.com">hello@beebox360.com</a></MyDiv>
                            </LiTag>
                        </UlTag>
                    </MyDiv>
                    </Col>                      
                    </Row>
                    <Row>
                      <Col md={12} xs={12}>
                        
                        <PTag className="copyright">Copyright &copy; {currentYear} <Link to="/" as={Link} onClick={handleLinkClick}>beebox360.com</Link> | All Right reserved.</PTag>
                      </Col>
                    </Row>
            </Container>
            <MyDiv className="whatsup-div">
            <a href="#" rel="noopener noreferrer"><img src={whatsupIcon}  alt="Whatsapp" /></a>
            </MyDiv>
            <MyDiv className="linkedin-div">
            <a href="https://www.linkedin.com/company/beebox-gmbh" target="_blank" rel="noopener noreferrer"><img src={linkedIcon}  alt="LinkedIn" /></a>
            </MyDiv>
       </MyDiv>
  
       </MyDiv>
    </>
        
    )
}
export default Footer;