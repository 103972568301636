import Container from 'react-bootstrap/Container';
import { useState } from "react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from "../assets/images/hedersvglogo.svg";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';


function Header({ expanded, setExpanded }) {
  

  return (
    <>
    <Navbar expanded={expanded} expand="lg" variant="dark" className="header">
      <Container  className="withcontainer">
        <Navbar.Brand to="/" as={Link}>
        <img src={Logo} className="img-responsive" alt='Logo'/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="menu-list">
            <Nav.Link to="/" as={Link} onClick={() => setExpanded(false)} className='home-link'>Home</Nav.Link>
            <HashLink smooth to="/#about-us-section" onClick={() => setExpanded(false)}>About Us</HashLink>
            <HashLink smooth to="/#beebox-section" onClick={() => setExpanded(false)}>The Beebox</HashLink>
            <HashLink smooth to="/#products-services" onClick={() => setExpanded(false)}>Products & Services</HashLink>
            <HashLink smooth to="/#smart-box-feature" onClick={() => setExpanded(false)}>Hive Login</HashLink>
            <HashLink smooth to="/#sustainability-section" onClick={() => setExpanded(false)}>Sustainability</HashLink>
            <HashLink smooth to="/#contact-section" onClick={() => setExpanded(false)}>Contact Us</HashLink>      
            </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export default Header;