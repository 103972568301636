import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import './App.css';
import Header from "./common/header";
import Footer from "./common/footer";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './assets/css/style.css'; // Corrected path
import './assets/css/responsive.css'; // Corrected path
import ScrollToTopButton from "./common/ScrollToTopButton";

const PrivacyPolicy = React.lazy(() => import("./pages/common/privacyPolicy"));
const Imprint = React.lazy(() => import("./pages/common/imPrint"));
const Home = React.lazy(() => import("./pages/home/home"));

function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="" index element={<React.Suspense fallback={<>...</>}><Home /></React.Suspense>}  />
            <Route path="/*"  element={<React.Suspense fallback={<>...</>}><Home /></React.Suspense>}  />
            <Route path="privacy-policy"  element={<React.Suspense fallback={<>...</>}><PrivacyPolicy /></React.Suspense>}  />
            <Route path="imprint"  element={<React.Suspense fallback={<>...</>}><Imprint /></React.Suspense>}  />
          </Route>
        </Routes>
      <ScrollToTopButton />
    </Router>
  );
}
export default App;

function Layout() {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <Header expanded={expanded} setExpanded={setExpanded} />
      <Outlet />
      <Footer setExpanded={setExpanded} />
    </>
  );
}
